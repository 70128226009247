import { EngineContext, getLayout as getGameLayout } from '../layouts/GameLayout'
import Link from 'next/link'
import Button from '../components/Button'
import { useContext, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { openPopout, setFirstPersonMode } from '../redux/site'
import MuteButton from '../components/MuteButton'
import Head from 'next/head'
import { Transition } from '@headlessui/react'
import useHover from '../hooks/useHover'
import { Fragment } from 'react'
import useExhibition from '../hooks/useExhibition'

const Home = ({ site, openPopout, setFirstPersonMode, ...rest }) => {
  const { engine, isHovering, scene } = useContext(EngineContext)
  const [footerRef, isFooterHovering] = useHover()
  const [isExploring, setRequestExplore, progress] = useExhibition()
  // const [isFirstPerson, setFirstPerson] = useState(false)

  // Set the in-game camera state
  // if (scene) {
  //   engine.fire((isFirstPerson || site.popoutState === 'onboarding') ? 'cameraManager:state:setFirstPersonMode' : 'cameraManager:state:setAnimationMode')
  //   engine.fire('cameraManager:transition:fadeIn')
  // }

  const isFirstPerson = site.exploreMode === 'firstperson'

  // Handle when first person is requested
  const onRequestFirstPerson = _ => {
    setRequestExplore()
    // setFirstPerson(true)
    // if (!site.hasCompletedOnboarding) openPopout('onboarding')
  }

  if (engine) {
    // disable if over the header
    engine && engine.fire('input:enable', !(isHovering || isFooterHovering))

    // Play the default audio track
    isFirstPerson && engine.fire('music:playTrack', 0)
  }

  return (
    <>
      <Head>
        <title>The Crypt</title>
        <meta name='description' content='An art museum for the digital age'/>

        {/* Twitter */}
        <meta name='twitter:card' content='summary' key='twcard' />
        <meta name='twitter:creator' content='@cryptdotart' key='twhandle' />

        {/* Open Graph */}
        <meta property='og:url' content='https://www.crypt.art' key='ogurl' />
        <meta property='og:image' content='https://www.crypt.art/images/atrium.png' key='ogimage' />
        <meta property='og:site_name' content='The Crypt' key='ogsitename' />
        <meta property='og:title' content='The Crypt' key='ogtitle' />
        <meta property='og:description' content='An art museum for the digital age' key='ogdesc' />

      </Head>

      <Transition 
        as={Fragment}
        show={!site.popoutState && !isFirstPerson}
        enter="filer transition transform duration-500 ease-in-out"
        enterFrom="translate-x-10 opacity-0 blur-lg "
        enterTo="translate-x-0 opacity-100 blur-none"
        leave="filter transition transform duration-500 ease-in-out"
        leaveFrom="translate-x-0 opacity-100 blur-none"
        leaveTo="-translate-x-10 opacity-0 blure-lg" >
        <div className='flex-grow p-6 md:p-4 space-y-8 select-none'>
          <h1 className='font-display leading-tight text-5xl md:text-6xl lg:text-8xl xl:text-9xl font-medium text-gray-50'>
            A gallery for rare<br />art &amp; collectibles
          </h1>
          <p className='hidden md:block max-w-prose text-lg text-white'>
            The Crypt - The cultural heart of the Virtually World.
          </p>
          <div className='flex space-x-8 items-center w-full pointer-events-auto'>
            <Button className='' onClick={onRequestFirstPerson}>
              Explore
            </Button>
            <Link href='/about'>
              <a className='text-sm font-detail tracking-widest underline text-white'>Find out more</a>
            </Link>
          </div>
        </div>
      </Transition>
      {progress === 1 && !site.popoutState && isFirstPerson && (
        <div ref={footerRef} className='hidden h-20 lg:flex container items-center space-x-4 self-end w-full text-white font-detail opacity-70 text-sm tracking-widest pointer-events-auto'>
          <MuteButton engine={engine} />
        </div>
      )}
    </>
  )
}

export const getStaticProps = async ({ params }) => {
  // Any page that uses the GameLayout must contain a sceneName
  return {
    props: { sceneName: 'atrium', heroImage: '/images/atrium.png' }
  }
}

const mapStateToProps = ({ site }, ownProps) => ({ ...ownProps, site })
const ConnectedHome = connect(mapStateToProps, { openPopout, setFirstPersonMode })(Home)

ConnectedHome.getLayout = getGameLayout
export default ConnectedHome
