import { useDispatch, useSelector } from "react-redux"
import { openPopout, setFirstPersonMode } from "../redux/site"
import { EngineContext } from '../layouts/GameLayout'
import { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"
import { RiContactsBookUploadLine } from "react-icons/ri"

const useExhibition = _ => {

  const site = useSelector(({ site }) => site)
  const dispatch = useDispatch()

  const { query } = useRouter()
  const { engine, progress, scene, isHovering } = useContext(EngineContext)
  // const [isFirstPerson, setFirstPerson] = useState(false)
  
  // Derived state
  const isFirstPerson = site.exploreMode === 'firstperson'
  const isAssetPath = !!(query.asset && query.asset.length > 0)

  // Set the in-game camera state
  if (scene) {
    engine.fire('cameraManager:transition:fadeIn')
    // console.log((isFirstPerson || isAssetPath) ? 'cameraManager:state:setFirstPersonMode' : 'cameraManager:state:setAnimationMode')
    engine.fire((isFirstPerson || isAssetPath) ? 'cameraManager:state:setFirstPersonMode' : 'cameraManager:state:setAnimationMode')
  }
  
  // console.log(engine.oc)
  useEffect(_ => {
    const onComponentSystemInit = _ => {
      engine.fire((isFirstPerson || isAssetPath) ? 'cameraManager:state:setFirstPersonMode' : 'cameraManager:state:setAnimationMode')
      engine.fire('cameraManager:transition:fadeIn')
    }
    engine && engine.pc.ComponentSystem.on('postinitialize', onComponentSystemInit)
    return _ => engine && pc.ComponentSystem.off('postinitialize', onComponentSystemInit)

  }, [engine])
  

  // Handle when first person is requested
  const onRequestFirstPerson = _ => {
    dispatch(setFirstPersonMode())
    // setFirstPerson(true)
    // if (!site.hasCompletedOnboarding) dispatch(openPopout('onboarding'))
  }

  if(engine) {
    // If we've come from a previous path, then push the camera back
    engine.fire(isAssetPath ? 'ui::interactionstart': 'ui::interactionend')

    // const onComponentSystemInit = _ => 
    // engine.pc.ComponentSystem.once('postinitialize', _ => pc.app.fire('cameraManager:state:setFirstPersonMode'))
    
    // disable first person controls if hovering or viewing an asset
    engine.fire('input:enable', !isHovering && !isAssetPath) 

    // navigate to the asset if the route specifies one
    if (scene && isAssetPath) {
      engine.fire('cameraManager:transition:teleportPlayer', {item: query.asset[0]})
    }
  }

  return [isFirstPerson, onRequestFirstPerson, progress]

}

export default useExhibition