import { useState } from 'react'
import { connect } from 'react-redux'

const MuteButton = ({ engine, group }) => {
  const [volume, setVolume] = useState(1)

  // if we're in a group call, mute the audio
  if (engine) engine.systems.sound.volume = group ? 0 : volume
  // if (Howler) Howler.volume(volume)

  return (
    <div className='transition-all hover:glow select-none cursor-pointer'>
      <img
        onClick={_ => setVolume(volume === 1 ? 0 : 1)}
        className='inline pointer-events-auto select-none'
        width='20'
        height='20'
        src={volume > 0 ? '/images/audio.svg' : '/images/audio-no-animation.svg'}
      />
    </div>
  )
}

const mapStateToProps = ({ groups, ownProps }) => ({ group: groups.group, ...ownProps })
export default connect(mapStateToProps, null)(MuteButton)
